import React from 'react';
import { Modal, Spin } from 'antd';
import "./ConfirmDlgWidget.css"
import { confirmable } from 'react-confirm';
const ConfirmDlgWidget = (props) => {
    const { show, proceed, title, okText, cancelText, content } = props;
    return (
        <div>
            <Modal
                visible={show}
                destroyOnClose={true}
                onCancel={() => {
                    proceed(false);
                }}
                closable={true}
                maskClosable={false}
                className="confirm-modal"
                title={title}
                okText={okText === undefined ? "Yes" : okText}
                cancelText={cancelText === undefined ? "Cancel":cancelText}
                onOk={() => {
                    proceed(true);
                }}>
                <div>
                    <p style={{color : "white"}}>{content}</p>
                </div>
            </Modal>
        </div>
    );
};

export default confirmable(ConfirmDlgWidget);