import { axiosPost } from "./ajaxServices";
import { urlContactUs } from "./CONSTANTS";

export const apiContactUs = (param) => {
  const url = urlContactUs;

  return new Promise((resolve, reject) => {
    axiosPost(url, param, "apiContactUs")
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
