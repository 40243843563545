import React, { useState } from 'react'
import "./SaleCategoryWidget.css"
import { useEffect } from 'react';
import { showConfirmDlgWidget } from 'components/ConfirmDlgWidget/showConfirmDlgWidget';
import { Input, Radio, Space, Row, Col, Checkbox } from 'antd';

function SaleCategoryWidget(props) {
  const { info, onClickSubCategory } = props

  useEffect(() => {

  }, []);

  const getBackgroundImage = () => {
    let style = "linear-gradient(to bottom, rgba(0, 0, 0, 0) 60px, rgba(0, 0, 0, 0.7) 100%), url(" + encodeURI(info.thumbnail_image) + ")"
    return style
  }

  return (
    <div className={"one-product-widget-container"}
      style={{ position: "relative" }}
      onClick={() => {
        onClickSubCategory(info)
      }}
    >
      <div className='one-product-category-rect'>
        <img src={encodeURI(info.thumbnail_image)} className='one-product-category-image' />
      </div>
      <div className="one-product-category-text">
        <Row justify="space-between" align="stretch ">
          <Col xs={24}>
            <h3 className="one-product-category-title">{info.name}</h3>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default SaleCategoryWidget
