import React from "react";
import { Col, Row, Tabs } from 'antd';
import "./SalesRecipe.css";
import { TitleSearch } from "components/SearchWidget/TitleSearch";
import { useState } from "react";
import { useEffect } from "react";
import TableWidget from "./TableWidget/TableWidget";
import SaleItemWidget from "../SaleItemWidget/SaleItemWidget";

function SalesRecipe(props) {
    const { dataList, AddCartAction, ownCommonData} = props;
    const [searchList, setSearchList] = useState([]);

    useEffect(() => {
        setSearchList(dataList)
    }, [dataList]);

    const handleSearch = (searchText) => {
        if (dataList === undefined) return
        const tmpList = dataList.filter((info) => info.name.toLowerCase().includes(searchText.toLowerCase()))
        setSearchList([...tmpList])
    };
    const handleChange = (event) => {
        if (dataList === undefined) return
        if (event.target.value === "") {
            setSearchList(dataList)
        }
        else {
            handleSearch(event.target.value)
        }
    };
    const handleChangeQtyforEachItem = (record) => {
        let updateData = [...dataList];
        var storeIndex = updateData.findIndex(x => x.id == record.id);
        updateData.splice(storeIndex, 1, record);
        setSearchList(updateData)
    }
    const recipeListUI = searchList == undefined || searchList.length == 0 ? <div>You don't have any Recipe list</div> : searchList.map((info, index) => {
        return (
            <Col xs={24} sm={24} md={12} lg={12} xl={12} key={index}>
                <SaleItemWidget
                    type="1" //Recipe
                    info={info}
                    ownCommonData={ownCommonData}
                    handleChangeQtyforEachItem={handleChangeQtyforEachItem}
                    AddCartAction={AddCartAction}
                    dataList={searchList}
                    setDataList={setSearchList}
                />
            </Col>
        );
    });
    return (
        <div>
            <TitleSearch
                onSearch={handleSearch}
                onChange={handleChange}
                onPressEnter={handleSearch}
            />
            <Row align={"middle"} gutter={16} style={{ marginTop: "10px", textAlign:'center' }} justify="center">
                {recipeListUI}
            </Row>
            {/* <div style={{ marginTop: 20 }}>
                <TableWidget
                    ownCommonData={ownCommonData}
                    handleChangeQtyforEachItem={handleChangeQtyforEachItem}
                    dataList={searchList}
                    setDataList={setSearchList}
                    AddCartAction = {AddCartAction}
                />
            </div> */}
        </div>
    );
}

export default SalesRecipe;
