import { Col, Row } from "antd";
import TempTopMenuWidget from "layouts/TempTopMenuWidget/TempTopMenuWidget";
import React from "react";
import { MetaTags } from "react-meta-tags";
import HeaderWidget from "./HeaderWidget";
import SalesPage from "./SalesPage/SalesPage";

const DashboardPage = () => {

  return (
    <div>
      <TempTopMenuWidget>
        <MetaTags>
          <title>Kalae User | Home</title>
          <meta
            name="description"
            content="Welcome to KalaeCafe. Read the terms of services  to understand how they apply to your use of our website."
          />
          <meta name="keywords" content="Best Deals" />
        </MetaTags>
        <Row align={'center'}>
          <Col xs={24} sm={16} md={16} lg={16} xl={16} >
            <SalesPage />
          </Col>
        </Row>


      </TempTopMenuWidget>
    </div>
  );
};

export default DashboardPage;
