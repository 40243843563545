import { Col, Row } from "antd";
import {
  ROUTE_PRIVACY_POLICY,
  ROUTE_TERMS_OF_SERVICE,
  ROUTE_CONTACT_US_UNREGISTER,
  ROUTE_HOME,
} from "navigation/CONSTANTS";
import React from "react";
import { Link } from "react-router-dom";
import "./LandingHeader.css";
import logoImage from "assets/images/logo.png";
import { useSelector } from "react-redux";
const LandingHeader = () => {
  const onClickStoreUrl = (url) => {
    var win = window.open(url, "_blank");
    win.focus();
  };
  const appDataStore = useSelector((x) => x.appDataStore);

  return (
    <div className="landing-header">
      <Row align={"middle"} gutter={[16, 16]}>
        <Col xs={0} md={0}>
          <img src={logoImage} alt="logoimage" style={{ maxWidth: "70%", height: 70 }} />
        </Col>
        <Col xs={24} md={15}>
          <Row justify={"space-between"}>
            <div className="landing-header-title">
              Kalae Cafe Menus
            </div>
          </Row>
        </Col>
        <Col xs={0} md={1}></Col>
       
      </Row>
    </div>
  );
};

export default LandingHeader;
