import React from 'react';
import "./SalesSuccess.css";

function SalesSuccess() {
    
    return (
        <div>
            SalesSuccess Page
        </div>
    );
};

export default SalesSuccess;