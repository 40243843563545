import React from "react";
import ContactUsWidget from "./ContactUsWidget";
import PageCategoryWidget from "./PageCategoryWidget";
import './LandingFooter.css'
const LandingFooter = (props) => {
  return (
    <div>
      {/* <ContactUsWidget /> */}
      <PageCategoryWidget />
    </div>
  );
};

export default LandingFooter;
