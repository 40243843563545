import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import io from 'socket.io-client';
import { SOCKET_EVENT_DATA, SOCKET_EVENT_NAME, SOCKET_URL } from "services/CONSTANTS";
import { HandleSocketEvent } from "redux/actions/socketActions";



export const socket = io(SOCKET_URL)

export const SocketService = () => {
  const dispatch = useDispatch();
  
  useEffect(() => {
    
    socket.on('connect', ()=>{
        console.log("socket connected!")
        clearSocketData()
    })
    
    socket.on('disconnect',()=>{
        console.log('server disconnected')
        clearSocketData()
    })
    
    socket.on('connect_error', ()=>{ setTimeout(()=>socket.connect(),5000) })
    
  
}, []);

  const clearSocketData = ()=>{
    var tmp = {}
    tmp[SOCKET_EVENT_DATA] = ""
    tmp[SOCKET_EVENT_NAME] = ""
    dispatch(HandleSocketEvent(tmp))
  }

  return (
    <div>
      
    </div>
  );
};
