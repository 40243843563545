import { Affix, Col, Row } from "antd";
import LandingFooter from "layouts/LandingFooter/LandingFooter";
import LandingHeader from "layouts/LandingHeader/LandingHeader";
import {
  ROUTE_ABOUT_US,
  ROUTE_HOME,
  ROUTE_LOGIN,
  ROUTE_PAIA,
  ROUTE_PRIVACY_POLICY,
  ROUTE_REGISTER,
  ROUTE_TERMS_OF_SERVICE,
} from "navigation/CONSTANTS";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "./TempTopMenuWidget.css";
const TempTopMenuWidget = (props) => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 300);
  }, []);
  const appDataStore = useSelector((x) => x.appDataStore);
  const { width } = appDataStore;
  return (
    <div>
      <LandingHeader />
      <div style={{ width: "100%", paddingTop: width >= 1000 ? 10 : 0 }}>
        {props.children}
      </div>
      <div style={{ height: 30 }} />
      <LandingFooter />
    </div>
  );
};

export default TempTopMenuWidget;
