import React from "react";
import { Col, Row, Tabs } from 'antd';
import { CSSTransition } from 'react-transition-group';
import "./SalesSubCategory.css";
import { TitleSearch } from "components/SearchWidget/TitleSearch";
import { useState } from "react";
import { useEffect } from "react";
import { apiSalesItemList, apiSubCategoryList } from "services/salesService";
import SaleCategoryWidget from "../SaleCategoryWidget/SaleCategoryWidget";
import SaleItemWidget from "../SaleItemWidget/SaleItemWidget";
import { ArrowLeftOutlined } from "@ant-design/icons";

function SalesSubCategory(props) {
    const { main_category_id, AddCartAction, ownCommonData, setIsBusy } = props;
    const [searchList, setSearchList] = useState([]);
    const [dataList, setDataList] = useState([]);
    const [itemList, setItemList] = useState([]);
    const [showSubCategory, setShowSubCategory] = useState(true);
    const [showItems, setShowItems] = useState(false);
    useEffect(() => {
        setIsBusy(true)
        apiSubCategoryList(main_category_id)
            .then((data) => {
                setIsBusy(false)
                setDataList(data)
            })
            .catch((err) => {
                setIsBusy(false)
                // message.error("Sorry! " + err);
            });
    }, [main_category_id]);

    const handleSearch = (searchText) => {
        if (itemList === undefined) return
        const tmpList = itemList.filter((info) => info.name.toLowerCase().includes(searchText.toLowerCase()))
        setSearchList([...tmpList])
    };
    const handleChange = (event) => {
        if (itemList === undefined) return
        if (event.target.value === "") {
            setSearchList(itemList)
        }
        else {
            handleSearch(event.target.value)
        }
    };
    const handleChangeInfoforEachItem = (record) => {
        let updateData = [...itemList];
        var storeIndex = updateData.findIndex(x => x.id == record.id);
        updateData.splice(storeIndex, 1, record);
        setSearchList(updateData)
    }

    const onClickSubCategory = (record) => {
        setItemList([])
        setSearchList([])

        setShowItems(true)
        setIsBusy(true)
        apiSalesItemList(record.main_category_id, record.id)
            .then((data) => {
                setIsBusy(false)
                setItemList(data)
                setSearchList(data)
            })
            .catch((err) => {
                setIsBusy(false)
                // message.error("Sorry! " + err);
            });
    }


    const subCategoryUI = dataList == undefined || dataList.length == 0 ? <div>You don't have any Recipe list</div> : dataList.map((info, index) => {
        return (
            <Col xs={24} sm={24} md={12} lg={12} xl={12} key={index}>
                <SaleCategoryWidget
                    info={info}
                    onClickSubCategory={onClickSubCategory}
                />
            </Col>
        );
    });

    const itemUI = searchList == undefined || searchList.length == 0 ? <div>You don't have any Item list</div> : searchList.map((info, index) => {
        return (
            <Col xs={24} sm={24} md={12} lg={12} xl={12} key={index}>
                <SaleItemWidget
                    type="1" //Recipe
                    info={info}
                    handleChangeInfoforEachItem={handleChangeInfoforEachItem}
                    AddCartAction={AddCartAction}
                    dataList={itemList}
                    setDataList={setItemList}
                />
            </Col>
        );
    });

    return (
        <div>
            <div>
                {showSubCategory && (
                    <Row align={"middle"} gutter={16} style={{ marginTop: "10px", textAlign: 'center' }} justify="center">
                        {subCategoryUI}
                    </Row>
                )}

                <CSSTransition
                    in={showItems}
                    timeout={300}
                    classNames="alert"
                    unmountOnExit
                    onEnter={() => setShowSubCategory(false)}
                    onExited={() => setShowSubCategory(true)}
                >
                    <div>
                        <div style={{
                            padding: "0px 0px 12px 0px",
                        }}>
                            <ArrowLeftOutlined
                                className="backward-button"
                                onClick={() => setShowItems(false)}
                            />
                        </div>
                        <TitleSearch
                            onSearch={handleSearch}
                            onChange={handleChange}
                            onPressEnter={handleSearch}
                        />
                        <Row align={"middle"} gutter={16} style={{ marginTop: "10px", textAlign: 'center' }} justify="center">
                            {itemUI}
                        </Row>

                    </div>

                </CSSTransition>
            </div>


        </div>
    );
}

export default SalesSubCategory;
