import React, { useState } from "react";

import logoImage from "assets/images/logo.png";
import { Col, Input, Row, Divider, BackTop } from "antd";
import { Link } from "react-router-dom";
import {
  ROUTE_ABOUT_US,
  ROUTE_CONTACT_US_UNREGISTER,
  ROUTE_HOME,
  ROUTE_LOGIN,
  ROUTE_PRIVACY_POLICY,
  ROUTE_TERMS_OF_SERVICE,
} from "navigation/CONSTANTS";
import MobileAppListWidget from "components/MobileAppListWidget/MobileAppListWidget";
import { showTextInputDialog } from "components/CommonDialogs/TextInputDialog/showTextInputDialog";
import { validateEmail } from "services/commonService";
import { showInformDialog } from "components/CommonDialogs/InformDialog/showInformDialog";
import { apiContactUs } from "services/contactService";
const PageCategoryWidget = () => {
  const [email, setEmail] = useState("");
  const onClickContact = async () => {
    if (validateEmail(email) == false) {
      await showInformDialog({
        title: "",
        canEscape: true,
        content: (
          <div style={{ fontSize: 18, textAlign: "center" }}>
            Please type valid email.
          </div>
        ),
      });
      return;
    }
    const text = await showTextInputDialog({
      title: <div>Please share your opinion with us</div>,
      isLongText: true,
      placeholder: "Type here",
      content: "",
    });
    if (text == false) return;
    apiContactUs({
      full_name: "Unregistered User",
      contact_info: email,
      subject: "From Landing Page",
      message: text,
    });
    await showInformDialog({
      title: "",
      canEscape: true,
      content: (
        <div style={{ fontSize: 18, textAlign: "center" }}>
          Thank you for your feedback.
        </div>
      ),
    });

    setEmail("");
  };
  return (
    <div style={{ padding: "2%" }}>
     
      <Divider type={"horizontal"} className="page-category-divider" />


      <div style={{ textAlign: "center", marginTop: 20, color: "rgb(102, 178, 69)" }}>
        2022 All rights reserved, www.kalaecafe.com
      </div>
      <BackTop />
    </div>
  );
};

export default PageCategoryWidget;
