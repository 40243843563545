import React from "react";
import { Button, Col, message, Row, Spin, Tabs } from 'antd';
import "./SalesPage.css";
import CommonDivWidget from "components/CommonDivWidget/CommonDivWidget";
import SalesInventory from "./SalesInventory/SalesInventory";
import SalesRecipe from "./SalesRecipe/SalesRecipe";
import { useEffect } from "react";
import { apiMainCategoryList } from "services/salesService";
import { useState } from "react";
import SalesCart from "./SalesCart/SalesCart";
import { useParams } from "react-router-dom";
import { AppleOutlined, ArrowLeftOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import SalesSubCategory from "./SalesSubCategory/SalesSubCategory";
import { CSSTransition } from 'react-transition-group';
import Sticky from 'react-stickynode';

const { TabPane } = Tabs;

const SalesPage = () => {
    const [showSubCategory, setShowSubCategory] = useState(true);
    const [showItems, setShowItems] = useState(false);
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const [hoverItem, setHoverItem] = useState();
    const [data, setData] = useState({
        table_name: params.get("table_name"),
    });
    const [isBusy, setIsBusy] = useState(false);
    const [ownCommonData, setOwnCommonData] = useState({});
    const [cartList, setCartList] = useState([]);
    const [dataList, setDataList] = useState([]);
    const [activeKey, setActiveKey] = React.useState('0')
    const onKeyChange = (key) => setActiveKey(key)
    useEffect(() => {
        setIsBusy(true)
        apiMainCategoryList()
            .then((data) => {
                setIsBusy(false)
                setDataList(data)
            })
            .catch((err) => {
                setIsBusy(false)
                // message.error("Sorry! " + err);
            });
    }, []);



    const AddCartAction = (record) => {
        let No = 0
        if (cartList.length !== 0) {
            No = cartList[cartList.length - 1].key + 1
        }

        // if (record.hasOwnProperty('has_yield')) {
        //     let item = ownCommonData.salable_inv_List.filter(e => e.id === record.id)[0];
        //     average_price_per_unit = 100 * item.average_price_per_unit / item.sell_price_percentage
        // }
        // else {
        //     let item = ownCommonData.salable_recipe_List.filter(e => e.id === record.id)[0]
        //     average_price_per_unit = item.total_price
        // }

        let tmp = {
            ...record,
            key: No,
            is_inventory: record.hasOwnProperty('has_yield'),
        }
        let tmpList = [...cartList]
        tmpList = [...tmpList, tmp]
        setCartList(tmpList)
    }
    const RemoveCartAction = (record) => {
        let updateData = [...cartList];
        var storeIndex = updateData.findIndex(x => x.key == record.key);
        updateData.splice(storeIndex, 1);
        setCartList(updateData)
    }

    const cleanAllCartAction = () => {
        setCartList([])
    }

    const tabUI = dataList == undefined || dataList.length == 0 ? "" : dataList.map((info, index) => {
        return (
            <TabPane tab={
                <span>
                    <AppleOutlined />
                    {info.name}
                </span>
            } key={`${index}`}>
                <SalesSubCategory
                    main_category_id={info.id}
                    setIsBusy={setIsBusy}
                    AddCartAction={AddCartAction}
                />
            </TabPane>

        );
    });

    return (
        <Spin spinning={isBusy}>
            <div className="container">
                <Sticky enabled={true} top={30} innerZ={9999}>
                    <div style={{ textAlign: "end" }}>
                        <Button style={{ height:45, borderRadius: 6, right: 12 }} onClick={() => {
                            setShowItems(true)
                        }} ><ShoppingCartOutlined />Checkout<span> ({cartList.length}) </span></Button>
                    </div>
                </Sticky>
                {showSubCategory && (
                    <Tabs defaultActiveKey="0" activeKey={activeKey} onChange={onKeyChange} centered size={"large"}>
                        {tabUI}
                    </Tabs>
                )}

                <CSSTransition
                    in={showItems}
                    timeout={300}
                    classNames="alert"
                    unmountOnExit
                    onEnter={() => setShowSubCategory(false)}
                    onExited={() => setShowSubCategory(true)}
                >
                    <div className="container">
                        <div style={{
                            padding: "0px 0px 12px 0px",
                        }}>
                            <ArrowLeftOutlined
                                className="backward-button"
                                onClick={() => setShowItems(false)}
                            />
                        </div>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <SalesCart
                                    table_name={data["table_name"]}
                                    dataList={cartList}
                                    RemoveCartAction={RemoveCartAction}
                                    cleanAllCartAction={cleanAllCartAction}
                                />
                            </Col>

                        </Row>

                    </div>
                </CSSTransition>

            </div>
        </Spin>
    );
}

export default SalesPage;
