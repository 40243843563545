import { ConsoleSqlOutlined } from "@ant-design/icons";
import { axiosDelete, axiosGet, axiosPost, axiosPut } from "./ajaxServices";
import { urlMainCategoryList, urlSubCategoryList, urlSalesItemList, urlMakePayWithCash, urlMakePayWithPaypal } from "./CONSTANTS";


export const apiMainCategoryList = () => {
  const url = urlMainCategoryList;
  return new Promise((resolve, reject) => {
    axiosGet(url)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const apiSubCategoryList = (main_category_id) => {
  const url = urlSubCategoryList;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("main_category_id", main_category_id);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
};


export const apiSalesItemList = (main_category_id, sub_category_id) => {
  const url = urlSalesItemList;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("main_category_id", main_category_id);
  formData.append("sub_category_id", sub_category_id);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
};


export function apiMakePayWithPaypal(data) {
  const url = urlMakePayWithPaypal;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("customer_name", data.customer_name);
  formData.append("totalBudget", data.totalBudget);
  formData.append("table_name", data.table_name);
  formData.append("cartList", JSON.stringify(data.cartList));
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}


export function apiMakePayWithCash(data) {
  const url = urlMakePayWithCash;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("customer_name", data.customer_name);
  formData.append("totalBudget", data.totalBudget);
  formData.append("table_name", data.table_name);
  formData.append("cartList", JSON.stringify(data.cartList));
  
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}